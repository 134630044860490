






























  import store from "@/store";
  import VesselsModule from "@/store/clients/Vessels.module";
  import { ExtendedVessel } from "@/types/Vessel";
  import { Vue, Component } from "vue-property-decorator";
  import { getModule } from "vuex-module-decorators";
  import SpeedLogAvailabilityStatus from "@/components/dataQuality/speedLog/SpeedLogAvailabilityStatus.vue";
  import SpeedLogTagHistory from "@/components/dataQuality/speedLog/SpeedLogTagHistory.vue";

  const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    SpeedLogAvailabilityStatus,
    SpeedLogTagHistory,
  },
})
  export default class SpeedLog extends Vue {
  selectedVessel = ""
  selectedTimespan = "Last hour"
  availability = 97
  outliers = 2
  available = false;

  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get isVesselSelected(): boolean {
    return this.selectedVessel !== "" && this.selectedVessel !== null;
  }
  }
